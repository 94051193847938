import classNames from 'classnames'
import { OfferCard } from '~/components'
import { SectionContainer } from '~/components/SectionContainer'
import { OfferCardsGridProps } from './OfferCardsGrid.types'

export const OfferCardsGrid = ({
  cards,
  width = 'wide',
}: OfferCardsGridProps) => {
  const commonStyle = 'lg:h-full w-full min-h-[240px] md:min-h-[380px]'
  const largeStyle = 'lg:min-h-[722px] 2xl:max-h-max'
  const smallStyle = 'lg:min-h-[353px] 2xl:max-h-max'

  let index = 0
  const templates = []
  while (cards[index]) {
    const card = cards[index]

    if (card.size === 'large') {
      templates.push(
        <div key={index} className={classNames(commonStyle, largeStyle)}>
          <OfferCard {...card} />
        </div>
      )

      index++
    } else {
      const nextCard = cards[index + 1]
      templates.push(
        <div key={index} className='flex flex-col gap-3 lg:h-full lg:gap-4'>
          <div className={classNames('flex-1', commonStyle, smallStyle)}>
            <OfferCard {...card} />
          </div>
          <div className={classNames('flex-1', commonStyle, smallStyle)}>
            {nextCard?.size === 'small' && <OfferCard {...nextCard} />}
          </div>
        </div>
      )

      index += nextCard?.size === 'small' ? Number('2') : 1
    }
  }

  return (
    <SectionContainer
      width={width}
      innerClassName={classNames(
        'grid grid-cols-1 gap-3  md:grid-cols-2 lg:gap-4 lg:h-full lg:grid-cols-3'
      )}>
      {templates}
    </SectionContainer>
  )
}
